<template>
	<div>
		<Modal>
			<div class="title">Solicitud / Crédito</div>
			<div class="body">
				<fieldset class="container-fluid">
					<legend>Solicitud</legend>
					<div class="col-sm-12">
						<div class="row form-group">
							<label for="importe_solicitado" class="col-sm-2">Importe solicitado</label>
							 <div class="col-sm-4">
								<input v-model="importe_solicitado_format" type="text" name="importe_solicitado" id="importe_solicitado" class="form-control" readonly>
							</div>
							<label for="importe_seleccionado" class="col-sm-2">Importe seleccionado</label>
							 <div class="col-sm-4">
								<input v-model="importe_seleccionado_format" type="text" name="importe_seleccionado" id="importe_seleccionado" class="form-control" readonly>
							</div>
						</div>
						<div class="row form-group">
							<label for="importe_pre_autorizado" class="col-sm-2">Importe pre-autorizado</label>
							 <div class="col-sm-4">
								<input v-model="importe_pre_autorizado_format" type="text" name="importe_pre_autorizado" id="importe_pre_autorizado" class="form-control" readonly>
							</div>
							<label for="acepto_importe_pre_autorizado" class="col-sm-2">Acepto importe pre autorizado</label>
							 <div class="col-sm-4">
								<div class="custom-control custom-switch">
									<BSwitch v-model="solicitud_modal.acepto_importe_pre_autorizado" name="acepto_importe_pre_autorizado" id="acepto_importe_pre_autorizado" texton="Sí" textoff="No" readonly="1" />
								</div>
							</div>
						</div>
						<div class="row form-group">
							<label for="plazo" class="col-sm-2">Plazo</label>
							 <div class="col-sm-4">
								<input v-model="solicitud_modal.plazo" type="text" name="plazo" id="plazo" class="form-control" readonly>
							</div>

							<label for="descripcion_solicitud" class="col-sm-2">Descripción</label>
							 <div class="col-sm-4">
								<input v-model="solicitud_modal.descripcion_solicitud" type="text" name="descripcion_solicitud" id="descripcion_solicitud" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="metodo_preferido_contacto" class="col-sm-2">Método prefrerido</label>
							 <div class="col-sm-4">
								<input v-model="solicitud_modal.metodo_preferido_contacto" type="text" name="metodo_preferido_contacto" id="metodo_preferido_contacto" class="form-control">
							</div>
							<label for="ingreso_mensual" class="col-sm-2">Ingreso mensual</label>
							 <div class="col-sm-4">
								<input v-model="ingreso_mensual_format" type="text" name="ingreso_mensual" id="ingreso_mensual" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="descripcion_giro_negocio" class="col-sm-2">Descripción de giro de negocio</label>
							 <div class="col-sm-4">
								<input v-model="solicitud_modal.descripcion_giro_negocio" type="text" name="descripcion_giro_negocio" id="descripcion_giro_negocio" class="form-control">
							</div>
							<label for="acepta_politicas_privacidad" class="col-sm-2">Aceptó politicas de privacidad</label>
							 <div class="col-sm-4">
								<div class="custom-control custom-switch">
									<BSwitch v-model="solicitud_modal.acepta_politicas_privacidad" name="acepto_importe_pre_autorizado" id="acepto_importe_pre_autorizado" texton="Sí" textoff="No" readonly="1" />
								</div>
							</div>
						</div>
						<div class="row form-group">
							<label for="destino_credito_ejercido" class="col-sm-2">Destino de crédito</label>
							 <div class="col-sm-4">
								<input v-model="solicitud_modal.destino_credito_ejercido" type="text" name="destino_credito_ejercido" id="destino_credito_ejercido" class="form-control">
							</div>
							<label for="descripcion_politicas" class="col-sm-2">Descripción politicas</label>
							 <div class="col-sm-4">
								<input v-model="solicitud_modal.descripcion_politicas" type="text" name="descripcion_politicas" id="descripcion_politicas" class="form-control">
							</div>
						</div>
					</div>
				</fieldset>
				<fieldset class="container-fluid">
					<legend>Crédito</legend>
					<div class="col-sm-12">
						<div class="row form-group">
							<label for="credito.folio" class="col-form-label col-sm-2">Folio</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.folio" type="text" name="credito_nuevo.folio" id="credito.folio" class="form-control"></div>
							<label for="credito_nuevo.tipo_credito" class="col-form-label col-sm-2">Tipo crédito</label>
							<div class="col-sm-4">
								<select v-model="solicitud_modal.credito.tipo_credito" type="text" name="credito_nuevo.tipo_credito" id="credito_nuevo.tipo_credito" class="form-control">
									<option v-for="tipo_credito in catalogos.products" :key="tipo_credito.id" :value="tipo_credito.id">{{ tipo_credito.name }}</option>
								</select>
							</div>
						</div>
						<div class="row form-group">
							<label for="credito_nuevo.moneda" class="col-form-label col-sm-2">Moneda</label>
							<div class="col-sm-4">
								<select v-model="solicitud_modal.credito.moneda" type="text" name="credito_nuevo.moneda" id="credito_nuevo.moneda" class="form-control">
									<option value="1">MXN</option>
								</select>
							</div>
							<label for="credito_nuevo.monto_deposito" class="col-form-label col-sm-2">Monto depósitos</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.monto_deposito" type="text" name="credito_nuevo.monto_deposito" id="credito_nuevo.monto_deposito" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="credito_nuevo.monto_credito" class="col-form-label col-sm-2">Monto crédito</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.monto_credito" type="text" name="credito_nuevo.monto_credito" id="credito_nuevo.monto_credito" class="form-control"></div>
							<label for="credito_nuevo.fecha_inicio" class="col-form-label col-sm-2">Fecha inicio</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.fecha_inicio" type="date" name="credito_nuevo.fecha_inicio" id="credito_nuevo.fecha_inicio" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="credito_nuevo.fecha_fin" class="col-form-label col-sm-2">Fecha fin</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.fecha_fin" type="date" name="credito_nuevo.fecha_fin" id="credito_nuevo.fecha_fin" class="form-control"></div>
							<label for="credito_nuevo.plazo" class="col-form-label col-sm-2">Plazo</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.plazo" type="text" name="credito_nuevo.plazo" id="credito_nuevo.plazo" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="credito_nuevo.cuotas" class="col-form-label col-sm-2">Cuotas</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.cuotas" type="text" name="credito_nuevo.cuotas" id="credito_nuevo.cuotas" class="form-control"></div>
							<label for="credito_nuevo.meses" class="col-form-label col-sm-2">Meses</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.meses" type="text" name="credito_nuevo.meses" id="credito_nuevo.meses" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="credito_nuevo.tasas_interes_ordinario" class="col-form-label col-sm-2">Interes ordinario</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.tasas_interes_ordinario" type="text" name="credito_nuevo.tasas_interes_ordinario" id="credito_nuevo.tasas_interes_ordinario" class="form-control"></div>
							<label for="credito_nuevo.tasa_interes_moratorio" class="col-form-label col-sm-2">Interes moratorio</label>
							<div class="col-sm-4"><input v-model="solicitud_modal.credito.tasa_interes_moratorio" type="text" name="credito_nuevo.tasa_interes_moratorio" id="credito_nuevo.tasa_interes_moratorio" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="credito_nuevo.interes_genera_iva" class="col-form-label col-sm-2">Interes genera IVA</label>
							<div class="col-sm-4">
								<select v-model="solicitud_modal.credito.interes_genera_iva" name="credito_nuevo.interes_genera_iva" id="credito_nuevo.interes_genera_iva" class="form-control">
									<option value="1">Sí</option>
									<option value="0">No</option>
								</select>
							</div>
						</div>
					</div>
					<div v-if="false" class="col-sm-8">
						<div class="row">
							<div class="col-sm-4 offset-sm-8 mb-2"><button class="btn secondary-btn">Cuentas bancarias</button></div>
						</div>
						<table class="table table-seguros">
							<thead>
								<th>#</th>
								<th>Tipo cuenta</th>
								<th>Cuenta</th>
							</thead>
							<tbody>
								<tr>
									<td>#</td>
									<td>Tipo</td>
									<td>Cuenta</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-sm-12">
						<div v-if="seguros.length > 0" class="row">
							<div class="col-sm-2 offset-sm-10 mb-2"><button class="btn secondary-btn" @click="modal_seguros=1">Agregar seguro</button></div>
						</div>
						<table class="table table-seguros">
							<thead>
								<th>#</th>
								<th>Tipo de seguro</th>
								<th>&nbsp;</th>
							</thead>
							<tbody>
								<tr v-for="(seguro, index) in solicitud_modal.credito.seguros">
									<td>{{ index+1 }}</td>
									<td>{{ seguro.name ? seguro.name : seguro.tipo }}</td>
									<td><div class="icon-cross" title="Eliminar fondeador" @click="eliminar_seguro(seguro)"></div></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-sm-12">
						<div class="row">
							<div class="col-sm-2 offset-sm-10 mb-2"><button class="btn secondary-btn" @click="open_modal_nuevo_fondeador">Agregar fondeador</button></div>
						</div>
						<table class="table table-fondeadores">
							<thead>
								<th>#</th>
								<th>Fondeador</th>
								<th>Porcentaje</th>
								<th>&nbsp;</th>
							</thead>
							<tbody>
								<tr v-for="(credito_fondeador,index) in solicitud_modal.credito.fondeadores" :key="index">
									<td>{{ index+1 }}</td>
									<td>{{ nombre_fondeador(credito_fondeador.fondeador_id) }}</td>
									<td>{{ credito_fondeador.porcentaje }}%</td>
									<td>
										<div class="icon-cross" title="Eliminar fondeador" @click="eliminar_fondeador(credito_fondeador)"></div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</fieldset>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="credito_modal_cerrar(1)">Actualizar crédito</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="credito_modal_cerrar(0)">Descartar cambios</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="modal_nuevo_fondeador!=0" @close="cancelar_agregar_fondeador" :options="{close: true}">
			<form @submit.prevent="agregar_fondeador">
				<div class="title">Agregar fondeador</div>
				<div class="body">
					<div class="row form-group">
						<label for="nuevo_fondeador.id" class="col-form-label col-sm-2">Fondeador</label>
						<div class="col-sm-10">
							<select v-model="nuevo_fondeador.fondeador_id" name="credito_nuevo.cuenta_financiera" id="credito_nuevo.cuenta_financiera" class="form-control">
								<option v-for="fondeadores in fondeadores_sin_usar" :key="fondeadores.id" :value="fondeadores.id">{{ fondeadores.name }}</option>
							</select>
						</div>
					</div>
					<div class="row form-group">
						<label for="nuevo_fondeador.porcentaje" class="col-form-label col-sm-2">Porcentaje</label>
						<div class="col-sm-10"><input v-model="nuevo_fondeador.porcentaje" ref="porcentaje_fondeador" type="number" min="1" max="100" name="nuevo_fondeador.porcentaje" id="nuevo_fondeador.porcentaje" class="form-control"></div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">Agregar</button></div>
						<div class="col-sm-2"><button type="button" class="btn danger-btn" @click="cancelar_agregar_fondeador">Cancelar</button></div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="modal_seguros!=0" :options="{close: true, width: '30vw'}" @close="modal_seguros=0">
			<div class="title">Agregar seguro</div>
			<div class="body">
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Seguro</label>
					<div class="col-sm-10">
						<Radio v-for="(seguro, index) in seguros" :key="index" v-model="modal_tipo_seguros" name="contacto_preferida" :id="'contacto_preferida_'+(index+1)" :value="$helper.toJson(seguro)" :label="seguro.name" />
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="agregar_seguro">Agregar</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="modal_seguros=0">Cancelar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import Radio from '@/components/Form/Radio'
	import BSwitch from '@/components/Form/Switch'

	import api from '@/apps/clientes/api/clientes'

	export default {
		components: {
			Modal, Radio, BSwitch
		}
		,props: {
			solicitud: {
				type: Object
				,default: () => ({
					creditos: []
				})
			},
			catalogos: {
				type: Object
				// ,default: () => {
				// 	return [];
				// }
			}
		}
		,data: () => {
			return {
				credito_nuevo: {
					folio: null
					,tipo_credito: null
					,moneda: null
					,monto_deposito: null
					,monto_credito: null
					,fecha_inicio: null
					,fecha_fin: null
					,plazo: null
					,cuotas: null
					,meses: null
					,tasas_interes_ordinario: null
					,tasa_interes_moratorio: null
					,interes_genera_iva: null
					,cuenta_pagos: null
					,fondeadores: []
					,seguros: []
				}
				,solicitud_modal: {
					credito: {
						folio: null
						,tipo_credito: 1
						,moneda: 1
						,monto_credito: null
						,monto_deposito: null
						,fecha_inicio: null
						,fecha_fin: null
						,plazo: null
						,cuotas: null
						,meses: null
						,tasas_interes_ordinario: null
						,tasa_interes_moratorio: null
						,interes_genera_iva: null
						,cuenta_financiera: null
						,cuenta_pagos: null
						,seguros: []
						,fondeadores: []
						,estatus: null
					}
				}
				,modal_nuevo_fondeador: 0
				,credito_modal: 0
				,nuevo_fondeador: {
					fondeador_id: 1
					,porcentaje: 1
				}
				,tipo_seguro: null
				,modal_seguros: 0
				,modal_tipo_seguros: null
			}
		}
		,mounted: function() {

			this.solicitud_modal = this.$helper.clone(this.solicitud);

			if (!this.solicitud_modal.credito) {
				this.solicitud_modal.credito = {
						folio: null
						,tipo_credito: 1
						,moneda: 1
						,monto_credito: null
						,monto_deposito: null
						,fecha_inicio: null
						,fecha_fin: null
						,plazo: null
						,cuotas: null
						,meses: null
						,tasas_interes_ordinario: null
						,tasa_interes_moratorio: null
						,interes_genera_iva: null
						,cuenta_financiera: null
						,cuenta_pagos: null
						,seguros: []
						,fondeadores: []
						,estatus: null
					}
			}
			
			this.$log.info('solicitud_credito', this.solicitud_modal);
		}
		,methods: {
			agregar_credito: function() {
				this.solicitud_modal.creditos.push(this.credito_nuevo);

				this.credito_modal = this.solicitud_modal.creditos.length-1;

				this.credito_nuevo = {
					folio: null
					,tipo_credito: 1
					,moneda: 1
					,monto_credito: null
					,monto_deposito: null
					,fecha_inicio: null
					,fecha_fin: null
					,plazo: null
					,cuotas: null
					,meses: null
					,tasas_interes_ordinario: null
					,tasa_interes_moratorio: null
					,interes_genera_iva: null
					,cuenta_financiera: null
					,cuenta_pagos: null
					,seguros: []
					,fondeadores: []
					,estatus: null
				}
			}
			,open_modal_nuevo_fondeador: function() {
				this.modal_nuevo_fondeador = true;

				if (this.$refs.porcentaje_fondeador)
					setTimeout(() => {
						this.$refs.porcentaje_fondeador.focus();
					},100);
				
			}
			,nombre_fondeador: function(id) {
				let nombre = null;

				this.catalogos.funders.forEach(fondeador => {
					if (fondeador.id == id)
						nombre = fondeador.name
				})

				return nombre;
			}
			,credito_modal_cerrar: function(actualizar) {
				if (actualizar == 1)
					this.$emit('solicitud_update', this.solicitud_modal);
					
				this.$emit('close');
			}
			,cancelar_agregar_fondeador: function() {
				// this.$emit('close');
			}
			,agregar_fondeador: function(credito) {
				let total_porcentajes = 0;
				
				this.solicitud_modal.credito.fondeadores.forEach(fondeador => {
					total_porcentajes += parseInt(fondeador.porcentaje);
				});

				total_porcentajes += parseInt(this.nuevo_fondeador.porcentaje);
				this.$log.info('Total_porcentajes: ',total_porcentajes);

				if (total_porcentajes > 100)
					return this.$helper.showMessage('Error','El porcentaje en su totalidad debe ser 100%','error','alert');

				this.solicitud_modal.credito.fondeadores.push(this.nuevo_fondeador);
				

				this.cancelar_agregar_fondeador();
			}
			,cancelar_agregar_fondeador: function() {
				this.nuevo_fondeador = {
					fondeador_id: 1
					,porcentaje: 1
				}

				this.modal_nuevo_fondeador = false;
			}
			,agregar_seguro: function() {
				let seguro = this.$helper.fromJson(this.modal_tipo_seguros);
				this.$log.info('seguro', seguro);

				this.$log.info('Se intenta agregar seguro');
				this.solicitud_modal.credito.seguros.push(seguro);

				this.modal_seguros = 0;
				this.modal_tipo_seguros = null;
			}
			,buscar_seguro_agregado: function(seguro_a_buscar) {
				let encontrado = false;

				this.solicitud_modal.credito.seguros.forEach(seguro => {
					if (!encontrado) {
						if (typeof(seguro.core_id) != 'undefined') {
							if (seguro.core_id == seguro_a_buscar.id) {
								encontrado = true;
							}
						}else {
							if (seguro.id == seguro_a_buscar.id) {
								encontrado = true;
							}
						}
					}
				})

				return encontrado;
			}
			,eliminar_seguro: function(seguro_a_quitar) {
				let seguros = [];

				this.solicitud_modal.credito.seguros.forEach(seguro => {
					if (typeof(seguro.core_id) != 'undefined') {
						if (seguro.core_id != seguro_a_quitar.core_id) {
							seguros.push(seguro);
						}
					}else {
						if (seguro.id != seguro_a_quitar.id) {
							seguros.push(seguro);
						}
					}
				})

				this.solicitud_modal.credito.seguros = seguros;
			}
			,cancelar_agregar_credito: function() {
				this.credito_modal=0;
				this.credito_nuevo = {
					folio: null
					,tipo_credito: null
					,moneda: null
					,monto_deposito: null
					,monto_credito: null
					,fecha_inicio: null
					,fecha_fin: null
					,plazo: null
					,cuotas: null
					,meses: null
					,tasas_interes_ordinario: null
					,tasa_interes_moratorio: null
					,interes_genera_iva: null
					,cuenta_pagos: null
					,fondeadores: []
					,seguros: []
				}
			}
			,eliminar_fondeador: function(fondeador) {
				if (typeof(fondeador.solicitud_credito_id) != 'undefined') {
					this.quitar_fondeador(fondeador.id);
				}else {
					this.fondeador_id = fondeador.id;
					this.confirmar_eliminar_fondeador = true;
				}
			}
			,quitar_fondeador: async function(id) {
				try {
					let fondeadores = [];
					let fondeadorEliminar = null;
					this.solicitud_modal.credito.fondeadores.forEach(fondeador => {
						if (fondeador.id != id) {
							fondeadores.push(fondeador);
							fondeadorEliminar = fondeador;
						}
					});

					// this.$log.info('fondeadorEliminar: ', fondeadorEliminar);

					// if (fondeadorEliminar.solicitud_credito_id)
					// 	await api.eliminar_creditos_fondeadores(this.solicitud.id, this.solicitud.credito.id, fondeadorEliminar.id);

					this.solicitud_modal.credito.fondeadores = fondeadores;
				}catch(e) {
					this.$log.error('Error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
		}
		,computed: {
			fondeadores_sin_usar: function() {
				let fondeadores = [];
				
				this.catalogos.funders.forEach(fondeador => {
					let encontrado = false;

					this.solicitud_modal.credito.fondeadores.forEach(fondeador_usado => {
						if (!encontrado && fondeador.id == fondeador_usado.fondeador_id)
							encontrado = true;
					});

					if (!encontrado)
						fondeadores.push(fondeador);
				});

				this.nuevo_fondeador.fondeador_id = fondeadores[0].id;
				this.$log.info('nuevo_fondeador: ',this.nuevo_fondeador);

				return fondeadores;
			}
			,seguros: function() {
				let seguros_tmp = [], seguros = [];

				this.catalogos.products.forEach(producto => {
					if (producto.id == this.solicitud_modal.credito.tipo_credito) {
						seguros_tmp = producto.productInsurance;
					}
				})

				if (seguros_tmp.length > 0) {
					seguros_tmp.forEach(seguro => {
						if (!this.buscar_seguro_agregado(seguro))
							seguros.push(seguro);
					})
				}

				return seguros;
			}
			,importe_solicitado_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.importe_solicitado);
			}
			,importe_seleccionado_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.importe_seleccionado);
			}
			,importe_pre_autorizado_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.importe_pre_autorizado);
			}
			,ingreso_mensual_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.ingreso_mensual);
			}
			,monto_ejercido_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.ejercido.monto_ejercido);
			}
			,valor_avaluo_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.ejercido.valor_avaluo);
			}
			,comision_apertura_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.ejercido.comision_apertura);
			}
			,monto_retiro_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.ejercido.monto_retiro);
			}
			,monto_autorizado_comite_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud_modal.ejercido.monto_autorizado_comite);
			}
		}
		,watch: {
			solicitud_modal: {
				handler: function(solicitud, solicitudAnt) {
					
				},
				deep: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		width: 100%;

		thead {
			th {
				background-color: #333333;
				color: #fff;
				padding: 3px 8px;
			}
		}

		tbody {
			tr {
				td {
					padding: 2px 8px;

					.icon-cross {
						cursor: pointer;
					}
				}
			}

			tr:nth-child(2n+0) {
				background-color: #E6E6E6;
			}
		}
	}

	.table-fondeadores {
		thead {
			th:first-child {
				width: 5%;
			}

			th:last-child {
				width: 10%;
			}
		}

		tbody {
			tr {
				td:first-child, td:last-child {
					text-align: center;
				}
			}
		}
	}

	.table-seguros {
		thead {
			th:first-child {
				width: 5%;
			}

			th:last-child {
				width: 10%;
			}
		}

		tbody {
			tr {
				td:last-child {
					text-align: center;
				}
			}
		}
	}
</style>